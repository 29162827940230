import React, { useLayoutEffect } from "react";
import "./Stacks.css";
import img1 from "../../images/E-commerce_Checklist/img1.png";
import img2 from "../../images/E-commerce_Checklist/img2.png";
import img3 from "../../images/E-commerce_Checklist/img3.png";
import img4 from "../../images/E-commerce_Checklist/img4.png";
import img5 from "../../images/E-commerce_Checklist/img5.png";
import img6 from "../../images/E-commerce_Checklist/img6.png";

const ServiceStack = () => {
  const stackData = [
    {
      title: "1:1 Live Session",
      description:
        "Your consistent growth should never be questioned when you engage in frequent One on One meetings. Always trust in your development through consistent One on One sessions. By regularly participating in One on One sessions, you ensure your progress remains unquestionable.",
      image: img1,
    },
    {
      title: "Unlimited Chat with Mentor",
      description:
        "If you ever find yourself filled with doubt, don't hesitate to get the right advice and guidance from your trusted mentor by reaching out and communicating via Chat to ensure you stay on the right track and make informed decisions, fostering growth and confidence in your journey.",
      image: img2,
    },
    {
      title: "Task & Curated Resources",
      description:
        "Certainly! You will be officially certified and recognized for your successful completion of this comprehensive mentorship program, validating your hard work, dedication, and the valuable skills you have acquired throughout the process, marking a significant milestone in your professional development and career growth.",
      image: img3,
    },
    {
      title: "Regular Followups",
      description:
        "Remain inspired and maintain your momentum by staying consistent with regular follow-ups, ensuring continuous progress and keeping yourself on track towards achieving your goals. Regularly checking in will help sustain your motivation and commitment, fostering a productive and goal-oriented mindset.",
      image: img4,
    },
    {
      title: "Job Referrals",
      description:
        "Obtain valuable referrals from your mentor community to secure opportunities at leading product and service-based companies. By leveraging the network and endorsements from experienced mentors, you can enhance your chances of landing positions at top-tier organizations, ensuring a strong and successful career trajectory.",
      image: img5,
    },
    {
      title: "Certified",
      description:
        "Absolutely! You will be officially certified for your successful completion of this mentorship program, acknowledging your dedication, growth, and the skills you have developed throughout the journey. This certification will serve as a testament to your commitment and achievements in this comprehensive mentorship experience.",
      image: img6,
    },
  ];
  useLayoutEffect(() => {
    const cardCol = document.getElementById("cards-col");
    const cards = document.querySelectorAll(".card");
    const card_bodies = document.querySelectorAll(".card-body");

    cardCol.style.setProperty("--len", cards.length);
    cards.forEach((card, index) => {
      if (index != cards.length - 1) {
        card.style.setProperty("--index", index + 1);
        card.style.setProperty("--len", cards.length);
      } else {
        card.classList.add("py-[1.2rem_!important]");
      }
    });
    card_bodies.forEach((card_body, index) => {
      card_body.classList.add(
        `${index % 2 == 0 ? "card-body1" : "card-body2"}`
      );
      card_body.classList.add("max-sm:flex-col");
    });
  }, []);

  return (
    <div className="container">
      <ul id="cards-col" className="cards-col">
        {stackData.map((data) => (
          <li className="card max-sm:px-[1.2rem_!important]">
            <div className="card-body max-sm:flex-col max-sm:p-10 ">
              <div className="sm:w-[50%] flex items-center justify-center">
                <img src={data.image} />
              </div>
              <div className="sm:w-[40%] flex flex-col justify-between sm:gap-7">
                <h3 className="text-xl sm:text-3xl font-bold">{data.title}</h3>
                <p className="max-sm:text-sm">{data.description}</p>
                {/* <button className="w-[150px] bg-white hover:bg-[#262e45bb] text-[#262e45bb] hover:text-white  rounded-full py-2 font-semibold text-lg">
                  Get Started
                </button> */}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ServiceStack;
