import React from "react";
import { LiaAngleDownSolid } from "react-icons/lia";

const FAQ = (props) => {
  const showAnswer = () => {
    if (props.isActive) {
      props.setActive(null);
    } else {
      props.setActive(props.index);
    }
  };
  return (
    <div className="flex justify-center items-center text-white">
      <div
        onClick={showAnswer}
        className="flex flex-col gap-5 w-[90%] md:w-[80%] py-5 border-b  cursor-pointer
      "
      >
        <div className="flex items-center justify-between gap-2 text-xl font-semibold ">
          {props.que}
          <LiaAngleDownSolid
            className={` transition-transform duration-200 ${
              props.isActive ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
        <div
          className={`${props.isActive ? "block" : "hidden"} flex flex-col `}
          dangerouslySetInnerHTML={{ __html: props.ans }}
        ></div>
      </div>
    </div>
  );
};

export default FAQ;
