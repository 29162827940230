import React from "react";
import AboutComp from "../components/AboutComp";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <>
      <Helmet>
        <title>MentorQ: About Us</title>
        <meta
          name="description"
          content="Discover the experts behind our web and Android app development, digital marketing, and training programs. Join us on the journey to digital excellence."
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <div className="bg-[#345fe0dd] h-16"></div>
      <AboutComp />
    </>
  );
};

export default About;
