import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import ServiceStack from "../components/Home/Stacks";

import image1 from "../images/image1.png";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.jpg";
import image4 from "../images/image4.jpeg";
import image5 from "../images/image5.jpeg";
import image6 from "../images/image6.jpg";
import image7 from "../images/image7.png";
import image8 from "../images/image8.png";
import image9 from "../images/image9.jpg";
import image10 from "../images/image10.jpg";

// import WebDev from "../videos/WebDev.mp4";
// import MobileDev from "../videos/MobileDev.mp4";
// import SoftProd from "../videos/SoftProd.mp4";
// import DigiMrkt from "../videos/DigiMrkt.mp4";

import "./Home.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import Contact from "./Contact";
import ServiceCard from "../components/Services/ServiceCard";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { MdOutlineUpcoming } from "react-icons/md";

import WorkStep from "../components/Home/WorkStep";
import FAQ from "../components/Home/faq";

const Home = () => {
  const [activeFAQ, setActiveFAQ] = useState(0);
  const faqData = [
    {
      que: "Does Long-term mentorship really produce outcomes?",
      ans: `1:1 Long-term mentorship is a new concept in comparison to a typical course or a bootcamp.<br> Hence, your doubt is completely valid. So let’s us resolve your doubt with Preplaced mentor stats<br><ul class="list-disc pl-6"><li>Avg. 90% of mentees say they became more consistent with the help of 1:1 long-term mentorship (Compared to a 10% completion rate of courses)</li><li>Avg. 70% of mentees achieve their career aspirations with the power of 1:1 long-term mentorship (Compared to 30% of people who get success)</li></ul>Nothing is more powerful than 1:1 long-term mentorship when it comes to delivering results💪. <br>Working with an elite mentor who has been there done that simply transforms your career.`,
    },
    {
      que: "What should be the duration of my long-term mentorship?",
      ans: "Every long-term mentorship is 1:1 and completely personalised to help you achieve your goals in the fastest & most efficient manner. Hence, to understand the duration, you can book a trial session with the mentor. The mentor would recommend a duration ssm on your current situation & goals.",
    },
    {
      que: "How many sessions can I have with the mentor?",
      ans: `Great question. Before we answer this question, we would like to tell you that Long-term mentorship is not just about sessions. Long-term Mentorship ≠ Sessions. A bulk of the magic happens outside sessions. Sessions are just one of the interactions that you can have with mentors.<br><br>Coming to the question<br><ul class="list-disc pl-6"><li>On average we see mentors interact via 4-8 sessions, regular tasks, daily chats, follow-ups and even instant calls.</li><li>A rough estimate of sessions &amp; total interactions is available on the side panel, below the pricing. And also in the “How it works” section of the profile.</li></ul><br><span class="">Tip:</span><br>Every Preplaced mentor feels it is their responsibility to achieve your dreams. And to do that, transactional relations and a fixed number of interactions are not enough. So when you gauge the mentors, we recommend not just using session count as a definition of value. But rather use description, skills &amp; trial sessions to select the mentor.`,
    },
    {
      que: "When is the right time to take long-term mentorship?",
      ans: `This is the most common category of questions that we get.<br><ul class="list-disc pl-6"><li>“Let me first prepare &amp; then take mentorship”</li><li>“I am a beginner. Can I take mentorship?”,</li></ul>If you have such doubts, then here is the answer:<br>No matter who you’re -  a beginner, intermediate or advanced, there are hundreds of mistakes that you can possibly make on the path to success. It is very easy to get lost in resources and waste time doing wrong things or even doing it wrongly. <br>Long-term mentorship handholds you to achieve your goals in the correct &amp; efficient manner irrespective of your stage. In fact, it is more impactful the longer you prepare with the mentor. Just 1 month of mentorship won’t be enough to change your fortunes.<br><br><span class="">Remember</span>1:1 Long-term Mentorship works at all stages,<br><ul class="list-disc pl-6"><li>Even when you are learning something new</li><li>And even when you have learnt it and not sure how to improve further.</li></ul>`,
    },
  ];

  const programData = [
    {
      id: 1,
      name: "Larry",
      subTitle: "Co-Founder, Mercom ",
      description:
        "“As an entrepreneur, I am faced with constant challenges. Having great mentors has taught me valuable lessons, saved me time and created life long friendships.”",
      profileImg: image2,
    },
    {
      id: 2,
      name: "Matt",
      subTitle: "CEO at Product Vessel ",
      description:
        "“I have been very fortunate to mentor amazing people that have worked for me over the years. Many have gone on to become entrepreneurs, leaders, and incredible mentors.”",
      profileImg: image3,
    },
    {
      id: 3,
      name: "Web Development",
      subTitle: "CEO at Product Vessel",
      description:
        "Data Science is a multidisciplinary field that combines techniques from statistics, computer science, and domain expertise to extract valuable insights and knowledge from data.",
      profileImg: image4,
    },
    {
      id: 4,
      name: "Matt",
      subTitle: "CEO at Product Vessel ",
      description:
        "“I have been very fortunate to mentor amazing people that have worked for me over the years. Many have gone on to become entrepreneurs, leaders, and incredible mentors.”",
      profileImg: image5,
    },
  ];

  const [imagesArr, setImagesArr] = useState([
    {
      src: image1,
      className: "left-96 top-24 max-xl:top-24 max-xl:left-96 max-md:hidden",
    },
    {
      src: image2,
      className: "left-32 top-44 max-xl:top-24 max-xl:left-6 max-md:hidden",
    },
    {
      src: image3,
      className: "left-40 top-86 max-xl:top-28 max-xl:left-48 max-md:hidden",
    },
    {
      src: image4,
      className:
        "left-96 bottom-24 max-xl:bottom-20 max-xl:left-48 max-md:hidden",
    },
    {
      src: image5,
      className:
        "left-56 bottom-32 max-xl:bottom-28 max-xl:left-14 max-md:hidden",
    },
    {
      src: image6,
      className: "right-96 top-24 max-xl:top-24 max-xl:right-72 max-md:hidden",
    },
    {
      src: image7,
      className: "right-24 top-40 max-xl:top-28 max-xl:right-12 max-md:hidden",
    },
    {
      src: image8,
      className:
        "right-48 bottom-72 max-xl:bottom-24 max-xl:right-50 max-md:hidden",
    },
    {
      src: image9,
      className:
        "right-40 bottom-40 max-xl:bottom-20 max-xl:right-14 max-md:hidden",
    },
    {
      src: image10,
      className:
        "right-96 bottom-24 max-xl:bottom-14 max-xl:right-96 max-md:hidden",
    },
  ]);

  const [categoryListings, setCategoryListings] = useState([
    { title: "Frontend Developer", mentorNum: 20 },
    { title: "Backend Developer", mentorNum: 20 },
    { title: "Fullstack Developer", mentorNum: 20 },
    { title: "DevOps / SRE", mentorNum: 20 },
    { title: "Data Analyst", mentorNum: 20 },
    { title: "Data Scientist", mentorNum: 20 },
    { title: "Data Engineer", mentorNum: 20 },
    { title: "AI / ML", mentorNum: 20 },
    { title: "Marketing", mentorNum: 20 },
    { title: "Sales", mentorNum: 20 },
    { title: "Business Analyst", mentorNum: 20 },
    { title: "Finance", mentorNum: 20 },
    { title: "Product Manager", mentorNum: 20 },
    { title: "UI/UX Designer", mentorNum: 20 },
    { title: "Project Manager", mentorNum: 20 },
    { title: "Program Manager", mentorNum: 20 },
  ]);

  return (
    <>
      <Helmet>
        <title>MentorQ</title>
        <meta
          name="description"
          content="Elevate Your Digital Presence - Web & App Development, Digital Marketing, Training, and Internships. Your Path to Success Starts Here."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <section className=" relative h-[100vh_!important] sm:min-h-[102vh] sm:w-[99.3vw_!important] w-[100%] flex items-center justify-center pt-10">
        <div>
          {imagesArr?.map((image, index) => {
            const randomInt = getRandomInt(50, 90);
            return (
              <img
                src={image.src}
                key={"hero" + index}
                className={
                  "icon-image absolute rounded-full " + image.className
                }
                style={{ width: randomInt + "px", height: randomInt + "px" }}
              />
            );
          })}
        </div>
        <div className=" flex flex-col items-center justify-center gap-10 sm:gap-5 z-10 px-4  my-auto text-[#262e45bb]">
          <h1 className="text-5xl md:text-6xl text-center font-semibold">
            Supercharge your career with
            <br />
            <span className="text-[#345fe0bb]">Long Term Mentorship</span>.
          </h1>
          <p className=" text-center sm:text-start text-lg md:text-xl">
            Land your dream job, role, and company faster than ever with 1:1
            long term mentorship.
          </p>
          <Link
            to={"/recommend_mentor"}
            className="hover:bg-white hover:text-[#262e45] text-lg font-[600] py-4 px-8 rounded-full text-white bg-[#345fe0] transition-colors duration-400 z-30"
          >
            Find Your Mentor
          </Link>
        </div>
      </section>
      <WorkStep />
      <section className="text-[#262e45bb] p-8 sm:p-20 flex flex-col gap-10 bg-accent max-sm:text-center">
        <div className="text-center">
          <h3 className="text-4xl font-semibold py-5">
            Browse service by categories
          </h3>
          <p className="text-justify sm:text-center text-base sm:text-xl">
            With a seamless booking experience and a galaxy of skilled experts,
            success is just a click away.
          </p>
        </div>
        <div className="flex flex-wrap gap-6 justify-around">
          {categoryListings?.length ? (
            categoryListings.map((category) => (
              <div
                className="p-5 h-28 w-56 bg-transparent shadow-[0_0_15px_1px_#345fe044] hover:shadow-[#345fe088] hover:scale-105 transition-all duration-500 cursor-pointer flex flex-col justify-center items-center rounded-xl"
                key={category._id}
              >
                <h2 className="font-semibold text-xl">{category.title}</h2>
                <span className="">Browse {category.mentorNum} listings</span>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center text-2xl">
              <MdOutlineUpcoming size={90} className="text-neutral" />
              Coming Soon
            </div>
          )}
        </div>
      </section>
      <section>
        <div className="ecomm-services w-full py-16 px-[5px] sm:px-[6%] md:px-[9%] lg:px-[5rem] bg-[#345fe0dd] ">
          <div className="w-full">
            <div className="text-center">
              <h1 className="text-4xl font-semibold font-Poppins text-white tracking-wide mb-3">
                Don't just take our word for it!
              </h1>
              <p className="text-justify sm:text-center text-base sm:text-xl text-white/80">
                Hear what the community is saying about us
              </p>
            </div>
            <Swiper
              slidesPerView={1}
              spaceBetween={50}
              navigation={true}
              breakpoints={{
                850: { slidesPerView: 2 },
                1160: { slidesPerView: 3 },
              }}
              autoplay={{ delay: 2000, disableOnInteraction: false }}
              modules={[Pagination, Autoplay, Navigation]}
              className="mySwiper1"
              style={{ margin: "50px 0 0 0", zIndex: 0 }}
            >
              {programData.map((item, i) => {
                return (
                  <SwiperSlide
                    key={item.id}
                    style={{ background: "transparent" }}
                  >
                    <Link>
                      <ServiceCard
                        type={"ph_card"}
                        title={item.name}
                        subTitle={item.subTitle}
                        profileImg={item.profileImg}
                        description={item.description}
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>

      <section className=" flex flex-col justify-center items-center lg:px-36 text-[#262e45bb]">
        <h2 className="text-4xl font-semibold py-5 pt-20 text-center ">
          No need to Struggle Alone Anymore
        </h2>
        <p className="text-center text-xl">
          Long term mentorship get fully covered
        </p>
        <ServiceStack />
      </section>

      <section>
        <div className="ecomm-services w-full py-16 px-[5px] sm:px-[6%] md:px-[9%] lg:px-[5rem] bg-[#345fe0dd] ">
          <div className="w-full">
            <div className="text-center">
              <h1 className="text-4xl font-semibold font-Poppins text-white tracking-wide mb-3">
                Frequently asked Questions
              </h1>
              <p className="text-justify sm:text-center text-base sm:text-xl text-white/80">
                Find answers to commonly asked questions about Long Term
                Mentorship
              </p>
            </div>
            {faqData.map((item, i) => {
              return (
                <FAQ
                  isActive={activeFAQ == i}
                  setActive={setActiveFAQ}
                  index={i}
                  que={item.que}
                  ans={item.ans}
                />
              );
            })}
          </div>
        </div>
      </section>

      <section>
        <Contact visible={false} />
      </section>
      {/* <section className=" flex flex-col justify-center items-center px-36 text-[#262e45bb]">
        <h2 className="text-4xl font-semibold py-5 text-center ">
          Our Clients
        </h2>
        <Swiper
          slidesPerView={5}
          spaceBetween={50}
          autoplay={{ delay: 500, disableOnInteraction: false }}
          freeMode
          modules={[Pagination, Autoplay, FreeMode]}
          className="mySwiper"
          style={{ width: "70vw", height: "100px", margin: "50px 0" }}
        >
        {Array(15)
            .fill(null)
            .map(() => (
              <SwiperSlide style={{ background: "none", minWidth: "80px" }}>
              <img src={Img3} className="h-[100%]" />
              </SwiperSlide>
            ))}
        </Swiper>
      </section>
      <section className="flex flex-col items-center gap-10 py-24 overflow-hidden ">
        <h2 className="text-4xl font-semibold text-[#262e45bb] inline-block px-10 py-3 rounded-full text-center">
          Testimonials
        </h2>
        <Testimonial />
      </section> */}
    </>
  );
};

export default Home;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
