import React, { useEffect, useState } from "react";
import "./ServiceCard.css";

const ServiceCard = (props) => {
  const [type, setType] = useState("s_card");
  useEffect(() => {
    setType(props.type);
  }, [props.type]);
  return (
    <div
      className={`flex flex-col items-center gap-5 py-8 px-8 md:px-14 hover:shadow-xl shadow-white ${type}`}
    >
      {type !== "s_card" && (
        <div className="flex justify-center items-center bg-[#262e45] text-white  w-20 h-20 rounded-2xl left-3 -top-3 z-50 absolute overflow-hidden ">
          <img src={props.profileImg} />
        </div>
      )}
      <div className="text-center">
        <h3 className="text-xl  font-semibold">{props.title}</h3>
        <p className="text-xl font-light">{props.subTitle}</p>
      </div>
      <p className="text-justify md:text-center text-lg">{props.description}</p>
    </div>
  );
};

export default ServiceCard;
