import React, { useRef, useState, useLayoutEffect } from "react";
import axios from "axios";
import { FaRocket } from "react-icons/fa6";
import { IoDocumentText } from "react-icons/io5";
import { GiHoneycomb } from "react-icons/gi";
import {
  FiCloud,
  FiFilm,
  FiMessageSquare,
  FiUploadCloud,
} from "react-icons/fi";
import { CgBrowse, CgCalendarDates, CgMailOpen, CgPen } from "react-icons/cg";
import Profile from "../images/Profile.png";
import loadingImg from "../images/Rolling@1x-1.0s-200px-200px.gif";

const RecommendMentor = () => {
  const resumeRef = useRef();
  const uploadContRef = useRef();
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);
  const [uploadState, setUploadState] = useState("");
  const [styleClasses, setStyleClasses] = useState(
    "hovEffect transition-transform duration-75 absolute hidden md:block animate-swipeIn "
  );
  const [step, setStep] = useState(0);
  const [questions, setQuestions] = useState([
    `In the following series of numbers, identify the one that does not fit the pattern: 3, 6, 9, 12, 15, 18, 22.`,
    `You're leading a team project with conflicting opinions. How would you resolve the disagreement and ensure everyone is heard?`,
    `You have two equally qualified candidates for a job position. One has more experience, while the other has more innovative ideas. How do you decide whom to hire?`,
    `You have a complex project with multiple tasks and deadlines. How would you prioritize and organize your workflow to ensure everything gets done efficiently?`,
    `You're tasked with organizing a company event within a tight budget and short timeframe. Outline your step-by-step plan for executing the event successfully.`,
    `Explain a complex concept or process in your field of expertise to someone who has no prior knowledge in that area.`,
    `Spot the grammatical error in the following sentence: "The group of students are going on a field trip."`,
    "Describe a situation where you successfully motivated a team to achieve a challenging goal. What strategies did you use?",
    "You're in charge of selecting a vendor for a critical company service. How do you evaluate the various options and make a final decision?",
    "You're given a large amount of data to analyze and present to your team. How do you structure the information to ensure clarity and coherence in your presentation?",
  ]);
  const [answers, setAnswers] = useState([]);
  const [recommendedData, setRecommendedData] = useState({});
  const [similarity_score, setSimilarityScore] = useState();
  const [type, setType] = useState(1);

  const layerEffect = (e, layer) => {
    const speed = layer.getAttribute("speed");
    const x = (window.innerWidth - e.pageX * speed) / 100;
    const y = (window.innerHeight - e.pageY * speed) / 100;
    layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
  };

  const moveHandler = (e) => {
    [...document.querySelectorAll(".hovEffect")].map((layer) => {
      layerEffect(e, layer);
    });
  };

  useLayoutEffect(() => {
    let shadow;

    if (uploadState === "uploading") {
      shadow = "0 0 20px 1px #008cff";
      uploadContRef.current?.classList.add("animate-wiggle");
      uploadContRef.current?.childNodes[0].classList.add("animate-wiggle");
    } else {
      shadow = "0 0 20px 1px #6d6d6d";
      uploadContRef.current?.classList.remove("animate-wiggle");
      uploadContRef.current?.childNodes[0].classList.remove("animate-wiggle");
    }

    if (uploadContRef.current) {
      uploadContRef.current.style.boxShadow = shadow;
      uploadContRef.current.childNodes[0].style.boxShadow = shadow;
    }
  }, [uploadState, step]);

  const uploadHandler = async (e) => {
    // e.preventDefault();
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("mentee_resume_pdf", file);

      const response = await axios.post(
        // "http://127.0.0.1:5000/recommend_mentor",
        "https://mentorq-backend.onrender.com/recommend_mentor",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setRecommendedData(response.data?.recommended_text);
        setSimilarityScore(response.data?.similarity_score);
        setType(2);
      } else {
        console.log("Not Uploaded");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <section
      className="min-h-[100vh] w-full flex items-center justify-center my-auto "
      onMouseMove={moveHandler}
    >
      {type === 1 ? (
        <div
          className={`relative md:h-[70vh] w-full mx-48 text-[#008cff] ${
            step !== 0 && "max-md:mx-5"
          }`}
        >
          <CgBrowse
            className={"top-20 -left-44 " + styleClasses}
            speed={3}
            size={50}
            style={{ animationDuration: "700ms" }}
          />
          <FiMessageSquare
            className={"top-20 -right-44 " + styleClasses}
            speed={3}
            size={30}
            style={{ animationDuration: "900ms" }}
          />
          <FaRocket
            className={"left-8 top-0 z-10 " + styleClasses}
            speed={8}
            size={60}
            style={{ animationDuration: "1200ms" }}
          />
          <IoDocumentText
            className={"-left-10 top-48 " + styleClasses}
            speed={3}
            size={40}
            style={{ animationDuration: "1400ms" }}
          />
          <GiHoneycomb
            className={"left-16 top-96 " + styleClasses}
            speed={2}
            size={60}
            style={{ animationDuration: "1300ms" }}
          />
          <FiCloud
            className={"right-8 top-0 z-10 " + styleClasses}
            speed={9}
            size={60}
            style={{ animationDuration: "1350ms" }}
          />
          <FiFilm
            className={"right-0 top-56 " + styleClasses}
            speed={2}
            size={40}
            style={{ animationDuration: "1250ms" }}
          />
          <FiMessageSquare
            className={"right-16 top-96 " + styleClasses}
            speed={5}
            size={60}
            style={{ animationDuration: "600ms" }}
          />
          <CgPen
            className={"-bottom-12 right-80 " + styleClasses}
            speed={4}
            size={30}
            style={{ animationDuration: "700ms" }}
          />
          <FiMessageSquare
            className={"-bottom-20 left-80 " + styleClasses}
            speed={2}
            size={50}
            style={{ animationDuration: "900ms" }}
          />
          <CgCalendarDates
            className={"-bottom-7 -left-20 " + styleClasses}
            speed={7}
            size={60}
            style={{ animationDuration: "1800ms" }}
          />
          <CgMailOpen
            className={"w-28 -bottom-20 -right-20 " + styleClasses}
            speed={3}
            size={20}
            style={{ animationDuration: "1700ms" }}
          />
          {/* <img
            src={imgMain}
            className="hovEffect static md:absolute w-[28rem] left-28"
            speed={1}
          /> */}
          {step === 0 && (
            <div
              onClick={() => {
                !file && resumeRef.current.click();
              }}
              speed={2}
              className="z-50 w-[60vh] h-[60vh] max-sm:w-[130px] max-sm:h-[130px] min-h-[300px] min-w-[300px] rounded-full flex items-center justify-center transition-transform duration-75 hovEffect static m-auto animate-swipeIn "
              style={{ animationDuration: "2000ms" }}
              onDragOver={(e) => {
                e.preventDefault();
                if (uploadState !== "uploaded") {
                  setUploadState("uploading");
                }
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                if (uploadState !== "uploaded") {
                  setUploadState("dragOut");
                }
              }}
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files[0];
                if (
                  uploadState !== "uploaded" &&
                  file.type === "application/pdf" &&
                  file.size < 20 * 1024 * 1024
                ) {
                  setFile(file);
                  setUploadState("uploaded");
                } else {
                  alert("Only PDF files lesser than 20MB are allowed");
                  setUploadState("");
                }
              }}
              ref={uploadContRef}
            >
              <div className="w-[80%] h-[80%] rounded-full flex flex-col gap-5 justify-center items-center transition-transform duration-75">
                {file ? (
                  <>
                    <ul className="overflow-hidden text-center ">
                      <li title={file.name}>
                        {file.name.substring(0, 20) +
                          (file.name.length > 20 ? "..." : "")}
                      </li>
                    </ul>
                    <div className="flex flex-col items-center gap-2 py-4">
                      <button
                        onClick={() => {
                          setStep(1);
                        }}
                        className="font-semibold bg-[#635DFF] text-white hover:bg-[#635Daa] border border-gray-500 py-1 px-3 rounded-lg"
                      >
                        Proceed to Psychometric test
                      </button>
                      <button
                        className="font-semibold bg-[#635DFF] text-white hover:bg-[#635Daa] border border-gray-500 py-1 px-3 rounded-lg"
                        onClick={() => {
                          setFile(null);
                          setUploadState("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <FiUploadCloud size={100} className="w-[60%]" />
                    <input
                      type="file"
                      onChange={(e) => {
                        const file = e.currentTarget.files[0];
                        if (
                          file &&
                          file.type === "application/pdf" &&
                          file.size < 20 * 1024 * 1024
                        ) {
                          setFile(file);
                        } else {
                          alert("File size should be lesser than 20MB");
                        }
                      }}
                      ref={resumeRef}
                      accept="application/pdf"
                      hidden
                    />
                    <p className="text-lg font-semibold text-center text-[#6d6d6d]">
                      Upload Resume
                      <br /> max 20 mb
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
          {step !== 0 &&
            questions?.map((que, i) => (
              <div
                className={
                  step === i + 1
                    ? "visible flex flex-col m-20 max-md:m-0 p-5 min-h-[250px] rounded-2xl gap-3 shadow-[0_0_20px_3px_#008cff44] "
                    : "hidden"
                }
              >
                <h3 className="text-2xl">{que}</h3>
                <textarea
                  className="bg-[#008cff22] flex-1 w-full outline-none p-3 rounded-lg"
                  value={answers[i]}
                  onChange={(e) => {
                    setAnswers((currAns) => {
                      currAns[i] = e.target.value;
                      return currAns;
                    });
                  }}
                />
                {loading ? (
                  <img src={loadingImg} className="w-20 h-20 ms-auto" />
                ) : (
                  <div className="flex justify-end gap-4 ">
                    <button
                      className="px-2 py-1 rounded-md border border-[#008cff] hover:scale-105 transition-transform duration-200"
                      onClick={() => setStep((currStep) => currStep - 1)}
                      disabled={loading}
                    >
                      Back
                    </button>
                    <button
                      className="px-2 py-1 rounded-md text-white bg-[#008cff] hover:scale-105 transition-transform duration-200"
                      onClick={() => {
                        if (step < questions.length - 1) {
                          return setStep((currStep) => currStep + 1);
                        } else {
                          uploadHandler();
                        }
                      }}
                      disabled={loading}
                    >
                      {step < questions.length - 1 ? "Next" : "Submit"}
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
      ) : (
        <div className="w-full">
          <h3 className="max-sm:text-xl text-3xl font-semibold self-start text-center mt-10 max-xl:mt-24 animate-swipeIn">
            Recommended Mentor ( {(similarity_score * 100).toFixed(2)} % )
          </h3>
          <div
            className={
              "flex flex-col m-20 max-sm:mx-5 max-md:m-10 p-5 min-h-[250px] rounded-2xl gap-3 shadow-[0_0_50px_1px_#008cff44] animate-swipeIn"
            }
          >
            <div className="flex justify-between gap-5 max-md:flex-col max-md:items-center">
              <img src={Profile} className="max-h-48" />
              <div className="flex flex-col gap-3 w-full max-md:items-center max-md:text-center">
                <h4 className="max-sm:text-xl text-2xl font-semibold">
                  {recommendedData.name}
                  <br />
                  <span className="text-xs">({recommendedData?.title})</span>
                </h4>

                <p className="text-lg font-semibold">
                  Email:{" "}
                  <span className="font-normal">
                    {recommendedData?.contact?.email}
                  </span>
                </p>
                <p className="text-lg font-semibold">
                  Contact:{" "}
                  <span className="font-normal">
                    {recommendedData.contact?.phone}
                  </span>
                </p>
              </div>

              <div>{recommendedData?.about || recommendedData?.profile}</div>
            </div>
            {/* <p className="text-lg font-semibold">
              Experience:{" "}
              <span className="font-normal">{recommendedData.experience}</span>
            </p> */}
            <div className="flex gap-5 flex-wrap">
              <h4 className="text-lg font-semibold">Skills:</h4>
              {recommendedData?.skills?.map((skill) => (
                <span className="border border-[#008cff88] rounded-lg px-3 py-1 ">
                  {skill}
                </span>
              ))}
            </div>
            {/* <button
              className="px-2 py-1 rounded-md border border-[#008cff] hover:scale-105 transition-transform duration-200 "
              onClick={() => setStep((currStep) => currStep - 1)}
            >
              Back
            </button> */}
          </div>
        </div>
      )}
    </section>
  );
};

export default RecommendMentor;
