import React, { useState } from "react";
import logo from "../images/logo.png";
import logoWhite from "../images/logoWhite.png";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { IoReorderThree } from "react-icons/io5";

const Header = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const showModalHandler = () => {
    setShowSidebar(true);
  };

  document.addEventListener("scroll", () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  });
  return (
    <>
      <Sidebar
        className={`${showSidebar ? "block" : "hidden"}`}
        setShowModal={setShowSidebar}
      />
      <header
        className={`flex justify-between items-center px-5 fixed top-0 w-full z-40 transition-colors duration-300 ${
          scrolled ? "bg-[#ffffffbb] backdrop-blur-sm" : "bg-[#345fe0]"
        }`}
      >
        <Link to={"/"}>
          <img src={`${scrolled ? logo : logoWhite}`} className="w-16" />
        </Link>
        <div className=" gap-3 hidden sm:flex font-semibold">
          <Link
            to={"/"}
            className={`text-lg hover:text-white ${
              scrolled
                ? "text-[#000000cc] hover:bg-[#345fe0] "
                : "text-white hover:bg-[#345fe0]"
            } px-3 rounded-full`}
          >
            Home
          </Link>
          <Link
            to={"/connect_with_mentor"}
            className={`text-lg hover:text-white ${
              scrolled
                ? "text-[#000000cc] hover:bg-[#345fe0] "
                : "text-white hover:bg-[#345fe0]"
            } px-3 rounded-full`}
          >
            Mentor
          </Link>
          <Link
            to={"/about"}
            className={`text-lg hover:text-white ${
              scrolled
                ? "text-[#000000cc] hover:bg-[#345fe0] "
                : "text-white hover:bg-[#345fe0]"
            } px-3 rounded-full`}
          >
            About
          </Link>
          <Link
            to={"/services"}
            className={`text-lg hover:text-white ${
              scrolled
                ? "text-[#000000cc] hover:bg-[#345fe0] "
                : "text-white hover:bg-[#345fe0]"
            } px-3 rounded-full`}
          >
            Services
          </Link>
          <Link
            to={"/contact"}
            className={`text-lg hover:text-white ${
              scrolled
                ? "text-[#000000cc] hover:bg-[#345fe0] "
                : "text-white hover:bg-[#345fe0]"
            } px-3 rounded-full`}
          >
            Contact
          </Link>
        </div>
        <button onClick={showModalHandler} className="sm:hidden">
          <IoReorderThree
            size={30}
            className={`${scrolled ? "text-[#000000cc]  " : "text-white "}`}
          />
        </button>
      </header>
    </>
  );
};

export default Header;
