import React, { useEffect, useState } from "react";
import Profile from "../images/Profile.png";
import axios from "axios";

const ConnectWithMentor = () => {
  const [recommendedMentorData, setRecommendedMentorData] = useState();
  const [loading, setLoading] = useState(false);

  const getAllMentors = async (e) => {
    try {
      setLoading(true);

      const response = await axios.get(
        "https://mentorq-backend.onrender.com/all_mentors"
        // "http://127.0.0.1:5000/all_mentors"
      );

      if (response.data) {
        setRecommendedMentorData(Object.values(response.data?.all_mentors));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllMentors();
  }, []);
  console.log(recommendedMentorData);
  return (
    <section className="w-full flex items-center justify-center my-auto ">
      <div className="w-full">
        <h3 className="max-sm:text-2xl text-3xl font-semibold self-start text-center mt-20 animate-swipeIn ">
          Our Mentors
        </h3>
        {recommendedMentorData?.map((recommendedData) => {
          return (
            <div
              className={
                "flex flex-col m-20 max-sm:mx-5 max-md:m-10 p-5 min-h-[250px] rounded-2xl gap-3 shadow-[0_0_50px_1px_#008cff44] animate-swipeIn"
              }
            >
              <div className="flex justify-between gap-5 max-md:flex-col max-md:items-center">
                <img src={Profile} className="max-h-48" />
                <div className="flex flex-col gap-3 w-full max-md:items-center max-md:text-center">
                  <h4 className="max-sm:text-xl text-2xl font-semibold">
                    {recommendedData.name}
                    <br />
                    <span className="text-xs">({recommendedData?.title})</span>
                  </h4>

                  <p className="text-lg font-semibold">
                    Email:{" "}
                    <span className="font-normal">
                      {recommendedData?.contact?.email ||
                        recommendedData?.email}
                    </span>
                  </p>
                  <p className="text-lg font-semibold">
                    Contact:{" "}
                    <span className="font-normal">
                      {recommendedData.contact?.phone ||
                        recommendedData.contact}
                    </span>
                  </p>
                </div>

                <div>{recommendedData?.about || recommendedData?.profile}</div>
              </div>
              {/* <p className="text-lg font-semibold">
              Experience:{" "}
              <span className="font-normal">{recommendedData.experience}</span>
            </p> */}
              <div className="flex gap-5 flex-wrap">
                <h4 className="text-lg font-semibold">Skills:</h4>
                {recommendedData?.skills?.map((skill) => (
                  <span className="border border-[#008cff88] rounded-lg px-3 py-1 ">
                    {skill}
                  </span>
                ))}
              </div>
              {/* <button
              className="px-2 py-1 rounded-md border border-[#008cff] hover:scale-105 transition-transform duration-200 "
              onClick={() => setStep((currStep) => currStep - 1)}
            >
              Back
            </button> */}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ConnectWithMentor;
