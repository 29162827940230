import React, { useState } from "react";
import useInterval from "../../utils/useInterval";
import { Link } from "react-router-dom";
import work1 from "../../images/work1.png";
import work2 from "../../images/work2.png";
import work3 from "../../images/work3.png";

const WorkStep = () => {
  useInterval(() => {
    setActiveImage((prevImage) => (prevImage + 1) % workStepContent.length);
  }, 2000);

  const [workStepContent, setWorkStepContent] = useState([
    {
      img: work1,
      title: "1. Create your profile",
      description:
        "With our registration form that takes time to properly understands each person, we’ll be able to recommend tailored matches for you.",
      linkName: "Register",
      linkUrl: "",
    },
    {
      img: work2,
      title: "2. Search Mentor",
      description:
        "From recommendations, search, or referral, and then book a time to meet.",
      linkName: "Search Now",
      linkUrl: "",
    },
    {
      img: work3,
      title: "3. Make a connection",
      description: "With someone who could change your life!",
      linkName: "See Testimonials",
      linkUrl: "",
    },
  ]);
  const [activeImage, setActiveImage] = useState(0);

  return (
    <section className=" p-4 sm:p-10 w-[100%]  text-[#ffffffdd] bg-[#345fe0] min-h-[90vh] overflow-hidden">
      <div className="flex flex-col justify-center items-center gap-10">
        <div className="text-center">
          <h3 className="text-4xl font-semibold py-5">How MentorQ Works</h3>
          <p className="text-justify sm:text-center text-base sm:text-xl">
            With a seamless booking experience and a galaxy of skilled experts,
            success is just a click away.
          </p>
        </div>
        <div className="flex-col sm:flex-row flex-wrap flex w-full justify-around gap-10">
          {workStepContent.map((step, index) => (
            <div
              className=" sm:w-1/3 lg:w-[30%] flex flex-col sm:gap-5 h-auto "
              key={step.title + index}
            >
              <img
                src={step.img}
                className={`drop-shadow-[0_35px_35px_rgba(220,220,255,.3)] mx-auto ${
                  activeImage === index ? "work-image" : ""
                }`}
              />
              <div className="flex flex-col justify-between max-sm:px-5 md:px-14 gap-3 h-full">
                <div>
                  <h3 className="text-2xl font-semibold">{step.title}</h3>
                  <p>{step.description}</p>
                </div>
                <Link
                  to={step.linkUrl}
                  className="text-[#345fe0dd] bg-white font-semibold text-lg px-5 py-2  rounded-full sm:ml-0 self-center"
                >
                  {step.linkName}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkStep;
