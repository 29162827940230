import React from "react";
import ServiceCard from "../components/Services/ServiceCard";
import { Helmet } from "react-helmet-async";

const Services = () => {
  const ServiceCardData = [
    {
      title: "Personalized Mentor Matching",
      description:
        "At  MentorQ, we use advanced algorithms to provide personalized mentor matching based on your unique needs, goals, and preferences. By understanding your career aspirations and areas for growth, we connect you with mentors who have the right expertise and experience to guide you on your journey. Whether you’re looking to advance in your career, develop new skills, or seek advice on specific challenges, our mentor matching service ensures you find the perfect mentor to support your goals.",
    },
    {
      title: "Resume Scanning",
      description:
        "Our resume scanning service leverages sophisticated AI technology to analyze your resume and identify key strengths, skills, and areas for improvement. By understanding your professional background and accomplishments, we can better match you with mentors who align with your career path and can offer relevant advice. This service not only enhances the mentor matching process but also provides valuable insights to help you refine your resume and present your best self to potential employers and mentors.",
    },
    {
      title: "Psychometric Testing",
      description:
        "We offer comprehensive psychometric testing to gain deeper insights into your personality, work style, and cognitive abilities. These tests help us understand your unique characteristics and preferences, ensuring a more accurate mentor-mentee match. Psychometric testing also provides you with a better understanding of your strengths and development areas, enabling you to make informed decisions about your career and personal growth. Our psychometric assessments are designed to support your journey towards self-awareness and professional success.",
    },
    {
      title: "Continuous Support and Resources",
      description:
        " MentorQ is committed to providing ongoing support and resources to both mentors and mentees throughout their journey. Our platform offers a wealth of resources, including articles, webinars, and workshops, to help you develop new skills and stay updated on industry trends. We also provide continuous support through regular check-ins and feedback sessions, ensuring that you and your mentor are on track to achieve your goals. Our dedication to your success doesn’t end with the initial match – we are here to support you every step of the way.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>MentorQ: Services</title>
        <meta
          name="description"
          content="Unlock Your Digital Potential - Expert Web & Android Development, Strategic Digital Marketing, and Empowering Training Programs."
        />
        <link rel="canonical" href="/services" />
      </Helmet>
      <div className="bg-[#345fe0dd] h-16"></div>
      <section className=" py-16 px-4 md:px-16 ">
        <h1 className="text-4xl font-semibold text-center pb-10">
          Our Services
        </h1>
        <div className=" rounded-2xl grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-10 relative">
          {ServiceCardData.map((card) => {
            return (
              <ServiceCard
                title={card.title}
                description={card.description}
                type="s_card"
              />
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Services;
