import React from "react";
import footerImg from "../images/footer_img.png";
import logo from "../images/logoWhite.png";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillMail,
} from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#345fe0dd] flex flex-wrap items-center gap-10 justify-around py-10">
      <div className="w-[90%] sm:w-[50%] md:w-[30%] flex flex-col items-center text-justify sm:text-start gap-5 text-white">
        <img src={logo} className="w-20" />
        <div className="w-full flex items-center justify-center max-sm:gap-2 gap-5">
          <Link
            to="/home"
            className={`text-lg text-white hover:underline px-3 rounded-full`}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`text-lg text-white hover:underline px-3 rounded-full`}
          >
            About
          </Link>
          <Link
            to="/services"
            className={`text-lg text-white hover:underline px-3 rounded-full`}
          >
            Services
          </Link>
          <Link
            to="/contact"
            className={`text-lg text-white hover:underline px-3 rounded-full`}
          >
            Contact
          </Link>
        </div>
        <div className="flex items-center gap-5 ">
          <h4 className="flex items-center gap-2">
            <AiFillMail /> mentorq@gmail.com
          </h4>
          <h4 className="flex items-center gap-2">
            <BsFillTelephoneFill />
            +91 9111884992
          </h4>
        </div>
        <div className="flex gap-5">
          <button>
            <AiFillFacebook
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2 "
            />
          </button>
          <button>
            <AiFillInstagram
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2"
            />
          </button>
          <button>
            <AiOutlineTwitter
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2"
            />
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
